import { useRouter } from "next/router";

import { Button } from "@/components/ui/button";

export function ServerError() {
  const router = useRouter();
  const isHosting = router.pathname.includes("/hosting");

  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <div className="flex h-20 w-20 items-center justify-center rounded-full bg-gold-100">
        <img src="/icons/biology.svg" alt="500" />
      </div>
      <div className="flex flex-col items-center justify-center gap-6 px-4 text-center md:max-w-[698px]">
        <h2 className="mt-4">Oops, We’ve Hit a Snag!</h2>
        <div className="p2">
          Science is all about trial and error, and it seems we’ve encountered
          an experiment of our own. Our system is currently undergoing an
          unexpected test, but rest assured, our best minds are already on it.
        </div>
        <div className="p2">
          We appreciate your patience and understanding. If you need immediate
          assistance, our support team is ready to leap into action. Just reach
          out <a href="mailto:support@secondlab.com">support@secondlab.com</a>.
        </div>
        <div>
          <a href={isHosting ? "/hosting" : "/"}>
            <Button className="md:w-[553px]">Return Home</Button>
          </a>
        </div>
      </div>
    </div>
  );
}
